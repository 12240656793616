import React from "react";
import "./index.css";
export default function Loader() {
  return (
    <div className="outer-loader">
      <span className="loader-text">
        <b>STAAR</b> Exports&Imports
      </span>
      <div class="loader"></div>
    </div>
  );
}
